import axios from 'axios'
import { snackbarProviderRef } from 'common/components/NotistackProvider'
import { getLocalStorage, setLocalStorage } from 'common/utils/localstorage'
import { API_BASE_URL } from 'env'
import { renewAccessToken } from 'modules/auth/api'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'modules/auth/constants'

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use((config) => {
  const token = getLocalStorage(ACCESS_TOKEN_KEY)

  let clonedConfig = { ...config }
  if (token) {
    clonedConfig.headers.Authorization = `Bearer ${token}`
  }

  return {
    ...clonedConfig,
    cancelToken: axios.CancelToken.source().token,
  }
})

let isRefreshing = false

axiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const { response, config } = err
    if (response) {
      const status = response.status

      if (status === 401 && !config._retry) {
        config._retry = true
        if (!isRefreshing) {
          isRefreshing = true
          try {
            const rf = getLocalStorage(REFRESH_TOKEN_KEY)
            const response = await renewAccessToken(rf)
            const {
              data: { accessToken, refreshToken },
            } = response.data
            setLocalStorage(ACCESS_TOKEN_KEY, accessToken)
            setLocalStorage(REFRESH_TOKEN_KEY, refreshToken)
            config.headers.Authorization = `Bearer ${accessToken}`
            isRefreshing = false
            return axiosInstance(config)
          } catch (err) {
            isRefreshing = false
            localStorage.clear()
            window.location.replace('/auth/login')
            throw err
          }
        }
      }

      const skipStatuses = [400, 401, 409]
      if (!skipStatuses.includes(status)) {
        const errorMessage = 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
        snackbarProviderRef.current?.enqueueSnackbar(errorMessage, {
          variant: 'error',
        })
      }
    }

    return Promise.reject(err)
  }
)

export default axiosInstance
